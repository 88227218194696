import React from 'react';

const SectionTitle = ({ children }) => (
    <h3 className="text-lg font-semibold mt-4 mb-2 text-gray-900">
        {children}
    </h3>
);

const SubSection = ({ title, children }) => (
    <div className="ml-4 mb-3">
        <h4 className="font-medium text-gray-800">{title}</h4>
        <div className="ml-4">{children}</div>
    </div>
);

const AnalysisDisplay = ({ content }) => {
    // Clean up the input content
    const cleanContent = content
        .replace(/^Thought:.*?\n/g, '')
        .replace(/Final Answer:\s*\n*/g, '')
        .trim();

    // Parse the content string into structured data
    const parseContent = (text) => {
        const sections = {};
        let currentSection = '';
        let currentSubSection = '';

        const lines = text.split('\n').map(line => line.trim()).filter(line => line);

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            // Check for main sections (now including the numbers and parenthetical text)
            if (line.match(/^\d+\.\s.*$/)) {
                currentSection = line;
                sections[currentSection] = {};
                continue;
            }

            // Check for subsections (they are the lines without dashes that follow main sections)
            if (!line.startsWith('-') && sections[currentSection]) {
                currentSubSection = line;
                if (!sections[currentSection][currentSubSection]) {
                    sections[currentSection][currentSubSection] = [];
                }
                continue;
            }

            // Content items (bullet points)
            if (line.startsWith('-') && currentSubSection && sections[currentSection]) {
                sections[currentSection][currentSubSection].push(line.substring(1).trim());
            }
        }

        return sections;
    };

    const renderScoreItem = (item) => {
        const [category, scoreStr] = item.split(':').map(s => s.trim());
        const score = parseInt(scoreStr) || 0;

        return (
            <div className="flex items-center justify-between">
                <span className="text-gray-700">{category}</span>
                <div className="flex items-center gap-2">
                    <div className="w-32 h-2 bg-gray-200 rounded-full">
                        <div
                            className="h-full bg-blue-500 rounded-full"
                            style={{ width: `${score}%` }}
                        />
                    </div>
                    <span className="font-medium w-8">{score}</span>
                </div>
            </div>
        );
    };

    const renderContentItem = (item) => {
        // Check if the item contains a rating in square brackets
        const ratingMatch = item.match(/(.*?)(?:\s*\[(.*?)\])?$/);
        if (ratingMatch) {
            const [, text, rating] = ratingMatch;
            return (
                <div className="text-gray-700 pl-4 border-l-2 border-gray-200 flex justify-between items-center">
                    <span>{text}</span>
                    {rating && (
                        <span className="ml-2 px-2 py-0.5 bg-blue-100 text-blue-800 text-sm rounded-full">
              {rating}
            </span>
                    )}
                </div>
            );
        }
        return (
            <div className="text-gray-700 pl-4 border-l-2 border-gray-200">
                {item}
            </div>
        );
    };

    const parsedContent = parseContent(cleanContent);

    return (
        <div className="bg-white rounded-lg shadow-sm p-6 space-y-4 max-w-4xl mx-auto">
            {Object.entries(parsedContent).map(([section, subSections]) => (
                <div key={section} className="border-b border-gray-200 pb-4 last:border-0">
                    <SectionTitle>{section}</SectionTitle>

                    {Object.entries(subSections).map(([subSection, items]) => (
                        <SubSection key={subSection} title={subSection}>
                            {items.map((item, index) => (
                                <div key={index} className="mb-2">
                                    {section.includes('SCORE SUMMARY')
                                        ? renderScoreItem(item)
                                        : renderContentItem(item)
                                    }
                                </div>
                            ))}
                        </SubSection>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default AnalysisDisplay;