import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { setCookie, getCookie } from '../../utils/cookies';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [isEmailUser, setIsEmailUser] = useState(false);
    const [isGuestUser, setIsGuestUser] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const sessionId = getCookie('sessionId');
        if (!sessionId) {
            createGuestSession();
        }

        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, []);

    const createGuestSession = () => {
        const guestId = crypto.randomUUID();
        setCookie('sessionId', guestId);
        setCookie('userRole', 'guest');
        setIsGuestUser(true);
        setCurrentUser({
            id: guestId,
            role: 'guest',
            name: `Guest-${guestId.slice(0,8)}`
        });
    };

    async function initializeUser(user) {
        if (user) {
            const isEmail = user.providerData.some(
                (provider) => provider.providerId === "password"
            );

            setCookie('sessionId', user.uid);
            setCookie('userRole', 'user');
            setCurrentUser({ ...user });
            setIsEmailUser(isEmail);
            setIsGuestUser(false);
            setUserLoggedIn(true);
        } else if (!getCookie('sessionId')) {
            createGuestSession();
        }

        setLoading(false);
    }

    const value = {
        userLoggedIn,
        isEmailUser,
        isGuestUser,
        currentUser,
        setCurrentUser
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}