
const ResultsStream = ({ messages, streamRef }) => (
    <div ref={streamRef} className="results-stream">
        {messages.map(message => (
            <div key={message.id} className={`stream-message ${message.className}`}>
                {message.content}
            </div>
        ))}
    </div>
);

export default ResultsStream ;