import React from 'react';

const ANALYSIS_STEPS = {
    crawl: { step: 1, label: 'Crawling website' },
    query_site_visually: { step: 2, label: 'Analyzing visuals' },
    query_site_textually: { step: 3, label: 'Analyzing content' },
    generate_feedback: { step: 4, label: 'Generating feedback' },
    check_for_feedback_reliability: { step: 5, label: 'Validating feedback' }
};

const ProgressTracker = ({ currentStep, isComplete }) => (
    <div className="progress-tracker">
        <div className="steps-container">
            {Object.values(ANALYSIS_STEPS).map(({ step, label }) => (
                <div key={step} className="step-item">
                    <div className={`step-circle ${
                        step < currentStep || isComplete ? 'completed' :
                            step === currentStep ? 'current' : ''
                    }`}>
                        {step < currentStep || isComplete ? '✓' : step}
                    </div>
                    <span className="step-label">{label}</span>
                </div>
            ))}
        </div>
        <div className="progress-bar-container">
            <div
                className="progress-bar-fill"
                style={{
                    width: `${isComplete ? 100 : (currentStep - 1) * 20}%`
                }}
            />
        </div>
    </div>
);

export default ProgressTracker;