import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import AnalysisDisplay from "./AnalysisDisplay";
import ProgressTracker from "./ProgressTracker";
import PERSONA_DETAILS from "./persona"
import ResultsStream from "./components/main/ResultsStream"
import { setCookie, getCookie } from './utils/cookies';


// utils.js
export const extractFinalAnalysis = (output) =>
    output[output.length - 1]?.messages?.[0]?.content ?? null;

// components/Header.js
const Header = () => (
    <>
        <h1 className="logo">
            <span>s</span><span>t</span><span>e</span><span>l</span><span>l</span><span>a</span>
        </h1>
        <div className="subtitle">
            Instant feedback from your target personas. Choose your virtual user
        </div>
    </>
);

// components/URLForm.js
const URLForm = ({ state, setState, handleSubmit }) => {
    useEffect(() => {
        const path = window.location.pathname.slice(1);
        if (path) {
            const cleanPath = path.replace(/\/$/, '');
            const formattedUrl = cleanPath.includes('://') ? cleanPath : `https://${cleanPath}`;
            setState(prev => ({ ...prev, url: formattedUrl }));
        }
    }, []);

    const handleUrlChange = (e) => {
        const value = e.target.value.trim();
        const formattedUrl = value.includes('://') ? value : `https://${value}`;
        setState(prev => ({ ...prev, url: formattedUrl }));
    };

    const handleReCaptchaVerify = useCallback((token) => {
        setState(prev => ({
            ...prev,
            token: token,
            error: '' // Clear any previous ReCAPTCHA errors
        }));
    }, [setState]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
     /*   if (!state.token) {
            setState(prev => ({
                ...prev,
                error: 'ReCAPTCHA validation is required. Please complete the verification.'
            }));
            return;
        }*/
        handleSubmit(e);
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <input
                type="url"
                className="url-input"
                value={state.url}
                onChange={handleUrlChange}
                placeholder="Enter your website URL..."
                required
            />
            <div className="controls">
                <select
                    className="persona-select"
                    value={state.selectedPersona}
                    onChange={(e) => setState(prev => ({ ...prev, selectedPersona: e.target.value }))}
                >
                    {Object.entries(PERSONA_DETAILS).map(([key, { title }]) => (
                        <option key={key} value={key}>
                            {`${key.charAt(0).toUpperCase() + key.slice(1)} (${title})`}
                        </option>
                    ))}
                </select>
                <div className="recaptcha-container">
                    <button
                        type="submit"
                        className="test-button"
                        disabled={state.isLoading }
                    >
                        {state.isLoading ? 'Processing...' : 'Test Website'}
                    </button>
                    <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        scriptProps={{
                            async: false,
                            defer: false,
                            appendTo: "head"
                        }}
                    >
                    <GoogleReCaptcha
                        onVerify={handleReCaptchaVerify}
                        refreshReCaptcha={state.refreshReCaptcha}
                    />
                    </GoogleReCaptchaProvider>
                </div>
            </div>
        </form>
    );
};


// components/WebSocketHandler.js
const useWebSocket = (state, setState, handleStreamMessage) => {
    const handleWebSocketConnection = async (url, selectedPersona) => {
        /*if (!state.token) {
            throw new Error('ReCAPTCHA verification required');
        }*/

        const userRole = getCookie('userRole');
        const currentEffort = parseInt(getCookie('effort') || '0');

        if (userRole === 'guest' && currentEffort >= 2) {
            setState(prev => ({
                ...prev,
                error: 'Free trial limit reached. Please login or register to continue using Stella.',
                needsAuth: true,
                currentStatus:""
            }));
            return null;
        }

        //wss://stella2-133440218446.us-central1.run.app/stream-stella
        const socket = new WebSocket("wss://stella2-133440218446.us-central1.run.app/stream-stella");

        socket.onopen = () => {

            socket.send(JSON.stringify({
                url,
                llm_id: "gpt-4o",
                persona_id: PERSONA_DETAILS[selectedPersona].id,
            }));
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.error) {
                    setState(prev => ({ ...prev, error: data.error }));
                    socket.close();
                    return;
                }

                if (data.actions?.length > 0) {
                    const action = data.actions[0];
                    const tool = action.tool;
                    setState(prev => ({
                        ...prev,
                        currentStep: tool === 'crawl' ? 1 :
                            tool === 'query_site_visually' ? 2 :
                                tool === 'query_site_textually' ? 3 :
                                    tool === 'generate_feedback' ? 4 :
                                        tool === 'check_for_feedback_reliability' ? 5 : prev.currentStep
                    }));
                }

                if (data.messages?.[0]?.content) {
                    const message = data.messages[0].content.split('\n\nAction')[0];
                    setState(prev => ({ ...prev, lastMessage: message }));
                    handleStreamMessage(message);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setState(prev => ({
                ...prev,
                error: 'WebSocket connection error',
                refreshReCaptcha: !prev.refreshReCaptcha
            }));
        };

        socket.onclose = () => {
            const currentEffort = parseInt(getCookie('effort') || '0');
            setCookie('effort', currentEffort + 1);
            setState(prev => ({
                ...prev,
                currentStatus: 'Analysis complete',
                isLoading: false,
                isComplete: true
            }));
        };

        return socket;
    };

    return handleWebSocketConnection;
};

// StellaStreamViewer.js
const StellaStreamViewer = () => {
    const [state, setState] = useState({
        url: '',
        isLoading: false,
        messages: [],
        currentStatus: '',
        error: '',
        selectedPersona: 'vincent',
        token: '',
        refreshReCaptcha: false,
        currentStep: 0,
        isComplete: false,
        lastMessage: null
    });

    const streamRef = useRef(null);

    useEffect(() => {
        if (streamRef.current) {
            streamRef.current.scrollTop = streamRef.current.scrollHeight;
        }
    }, [state.messages]);

    const handleStreamMessage = useCallback((data) => {
        const newMessage = {
            id: Date.now(),
            content: '',
            className: 'stream-message'
        };

        if (typeof data === 'string' && data.includes('FIRST IMPRESSION')) {
            newMessage.content = <AnalysisDisplay content={data} />;
            newMessage.className = 'analysis-result';
            setState(prev => ({ ...prev, messages: [...prev.messages, newMessage] }));
            return;
        }

        setState(prev => ({ ...prev, messages: [...prev.messages, newMessage] }));
    }, []);

    const handleWebSocketConnection = useWebSocket(state, setState, handleStreamMessage);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format URL for navigation
        //const cleanUrl = state.url.replace(/^https?:\/\//, '').replace(/\/$/, '');
        //window.history.pushState({}, '', `/${cleanUrl}`);

      /*  if (!state.token) {
            setState(prev => ({
                ...prev,
                error: 'ReCAPTCHA validation is required. Please complete the verification.'
            }));
            return;
        }*/

        setState(prev => ({
            ...prev,
            isLoading: true,
            error: '',
            messages: [],
            currentStatus: 'Analysis is starting...',
            currentStep: 0,
            isComplete: false,
            lastMessage: null
        }));

        try {
            await handleWebSocketConnection(state.url, state.selectedPersona);
        } catch (error) {
            setState(prev => ({
                ...prev,
                error: error.message,
                isLoading: false
            }));
        }
    };


    return (
        <div className="container">
            <Header />
            <div className="content-wrapper">
                <URLForm state={state} setState={setState} handleSubmit={handleSubmit} />

                {state.currentStep > 0 && (
                    <ProgressTracker
                        currentStep={state.currentStep}
                        isComplete={state.isComplete}
                    />
                )}

                {state.currentStatus && (
                    <div className="status-container">
                        <div>{state.currentStatus}</div>
                    </div>
                )}

                {state.error && (
                    <div className="error-message">{state.error}</div>
                )}

                {state.messages.length > 0 && (
                    <ResultsStream messages={state.messages} streamRef={streamRef} />
                )}
            </div>

            <div className="footer">
                © 2024 Stella - All rights reserved - This is Beta version - contact@stell.la
            </div>
        </div>
    );
};

export default StellaStreamViewer;