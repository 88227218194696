import './App.css';
import './StellaStyles.css';
import StellaStreamViewer from './StellaStreamViewer'
import Login from "./components/auth/Login";
import Register from "./components/auth/register";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import Header from "./components/auth/header";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";

function App() {
    const routesArray = [
        {
            path: "*",
            element: <StellaStreamViewer />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/register",
            element: <Register />,
        },

    ];

    let routesElement = useRoutes(routesArray);
    return (

            <AuthProvider>
                <Header/>
                <div className="flex min-h items-center justify-center bg-gray-50">
                    <div className="container mx-auto p-4">
                       {routesElement}
                    </div>
                </div>
            </AuthProvider>
);
}

export default App;