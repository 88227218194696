import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import { doSignOut } from '../../../firebase/auth'

const Header = () => {
    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    return (
        <nav className='flex flex-row gap-x-2 w-full z-20 p-2 fixed top-0 left-0 h-12 border-b place-content-between items-center bg-gray-200'>
            {userLoggedIn ? (
                <>
                    <Link className='text-sm text-blue-600 underline' to='https://www.stell.la'>
                        About us
                    </Link>
                    <button
                        onClick={() => doSignOut().then(() => navigate('/login'))}
                        className='text-sm text-blue-600 underline'
                    >
                        Logout
                    </button>
                </>
            ) : (
                <>
                    <Link className='text-sm text-blue-600 underline' to='https://www.stell.la'>
                        About us
                    </Link>
                    <Link className='text-sm text-blue-600 underline' to='/login'>
                        Login
                    </Link>

                </>
            )}

        </nav>
    )
}

export default Header