import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setCookie = (name, value, options = {}) => {
    const defaultOptions = {
        path: '/',
        maxAge: 86400, // 24 hours
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'strict'
    };

    cookies.set(name, value, { ...defaultOptions, ...options });
};

export const getCookie = (name) => {
    return cookies.get(name);
};

export const removeCookie = (name) => {
    cookies.remove(name, { path: '/' });
};